<template>
  <form class="pr-5" @submit="onSubmit">
    <MainTitle el="legend" class="mt-10 md:mt-20 text-lg lg:text-4xl md:text-left">
      <template #before>
        {{ $t('requestAQuote') }}
      </template>
    </MainTitle>

    <p class="mt-10 text-secondary-400 font-base">{{ $t('enterInfo') }}</p>
    <TextInput id="full-name" name="fullName" :label="$t('fullName')" type="text" autocomplete="name" border />

    <TextInput id="phone" name="phoneNumber" :label="$t('phoneNumber')" type="tel" autocomplete="tel" border />

    <TextInput id="email" name="email" :label="$t('email')" type="email" autocomplete="email" />

    <TextInput id="company" name="company" :label="$t('company')" type="company" />

    <AppButton class="w-full lg:w-1/2 mt-10 font-bold h-14" :disabled="isSubmitting">
      <Spinner v-if="isSubmitting" class="w-5 h-5" />
      <span v-else>{{ $t('submit') }}</span>
    </AppButton>
  </form>
</template>
<script setup lang="ts">
import * as yup from 'yup';
const { close } = useToggleCommercialModal();
const props = defineProps({
  sku: {
    type: String,
    required: true,
  },
});
const { handleSubmit, isSubmitting, resetForm } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      fullName: yup.string().required(),
      phoneNumber: yup.string().required(),
      email: yup.string().email().required(),
      company: yup.string().required(),
    }),
  ),
});

const { requestQuote } = useCommercialQuote();
const { error, success } = useAlerts();
const { t: $t } = useI18n({
  useScope: 'local',
});

const onSubmit = handleSubmit(async values => {
  try {
    const data = await requestQuote(props.sku, values);

    if (data) {
      success($t('success.title').toString(), $t('success.message').toString());
      resetForm();
      close();
    }
  } catch (e) {
    error($t('errors.generic.title').toString(), $t('errors.generic.message').toString());
    // eslint-disable-next-line no-console
    console.error(e);
  }
});
</script>

<i18n>
{
  "en": {
    "requestAQuote": "Request A Quote",
    "enterInfo": "Enter your information below and our sales team will be in touch as soon as possible",
    "success": {
      "title": "Successfully Requested A Quote",
      "message": "You've successfully requested a quote, our sales team will be in touch as soon as possible"
    },
    "fullName": "Full Name",
    "phoneNumber": "Phone Number",
    "email": "Email",
    "company": "Company",
    "submit": "Submit"
  },
  "ar": {
    "requestAQuote": "اطلب سعر",
    "enterInfo": "أدخل معلوماتك أدناه وسيكون فريق المبيعات لدينا على اتصال في أقرب وقت ممكن",
    "success": {
      "title": "تم طلب سعر بنجاح",
      "message": "لقد طلبت سعراً بنجاح ، سيقوم فريق المبيعات لدينا على اتصال في أقرب وقت ممكن"
    },
    "fullName": "الاسم الكامل",
    "phoneNumber": "رقم الهاتف",
    "email": "البريد الإلكتروني",
    "company": "الشركة",
    "submit": "إرسال"
  }
}
</i18n>
