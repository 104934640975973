import { useMutation } from 'villus';
import { RequestAQuoteDocument } from '~/graphql/Product';

export function useCommercialQuote() {
  const { isFetching, execute } = useMutation(RequestAQuoteDocument.toString());

  async function requestQuote(
    sku: string,
    params: {
      fullName: string;
      email: string;
      phoneNumber: string;
      company: string;
    },
  ) {
    const { error, data } = await execute({
      ...params,
      sku,
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  }

  return {
    requestQuote,
    isFetching,
  };
}
