<template>
  <RoundedCorner>
    <template #actions>
      <button
        class="CloseButton absolute md:top-10 right-0 left-0 md:left-auto mx-auto md:mx-0 md:right-10 top-50 w-13 h-13 rounded-[18px] [ transform ] bg-secondary-300 md:bg-transparent [ flex items-center justify-center ] z-1 -translate-y-1/2 md:transform-none"
        @click="close"
      >
        <svg-icon name="close" class="h-6 w-6" />
      </button>
    </template>
    <div
      class="RequestQuote transform md:transform-none -translate-x-1/2 bubble relative md:bg-white [ px-5 md:px-0 pt-5 md:pt-8 md:pl-20 ] text-primary-A900 md:rounded-tl-8xs"
    >
      <div class="overflow-auto">
        <RequestQuoteFields class="flex flex-col pb-5 h-full overflow-auto items-start" :sku="sku" />
      </div>
    </div>
  </RoundedCorner>
</template>
<script setup lang="ts">
defineProps<{
  sku: string;
}>();
const { toggleDefinedAlias } = useBottomSheet<{
  acHorsePower: number;
}>();

const close = () => {
  toggleDefinedAlias('commercialModal');
};
</script>

<style lang="postcss" scoped>
.RequestQuote {
  max-height: calc(100vh - 200px);
  width: 100vw;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 50%;

  @screen md {
    height: 100%;
    max-height: 100vh;
    width: 60vw;
    position: absolute;
    right: 0;
    top: unset;
    left: unset;
  }
}

.CloseButton {
  @screen md {
    box-shadow: 10px 20px -8px rgba(24, 145, 246, 0.2);
    filter: blur(0px);
  }
}
</style>
